/*
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.terms-and-conditions-screen {
    width: 100%;
    height: 100%;
}